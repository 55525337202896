import React, { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'; // 아이콘을 위해 react-icons 패키지를 사용합니다.
import Menubar from "../components/Menubar";
import { Container } from "../styles/styles";
import { ButtonStyle, InputLabelStyle, InputTextStyle, SignupContainer } from "../styles/SignupStyles";
import useApi from "../backend/users";
import {useNavigate} from "react-router-dom";

const SignupPage = () => {
    const [nickname, setName] = useState('');
    const [email, setEmail] = useState('');
    const [organization, setOrganization] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const navigate = useNavigate();


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    const { createUser } = useApi();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (nickname === '' || email === '' || organization === '' || password === '' || confirmPassword === '') {
            setErrorMessage('⚠️ Please fill in all fields');
            return;
        }
        if (password !== confirmPassword) {
            setErrorMessage('️⚠️ Passwords do not match');
            return;
        }
        const user = { nickname, email, organization, password };
        const result = await createUser(user);
        if (result.message) {
            alert(result.message);
        } else {
            alert('Sign up successful');
            navigate('/login');
            setName('');
            setEmail('');
            setOrganization('');
            setPassword('');
            setConfirmPassword('');
            setErrorMessage('');
        }
    };

    return (
        <Container style={{ minHeight: "1000px" }}>
            <Menubar />
            <div style={{ display: "flex", flexDirection: "column", backgroundColor: "white", width: "100%", height: "100%" }}>
                <SignupContainer>
                    <div style={{ color: '#21005D', fontSize: 35, fontFamily: 'DM Sans,sans-serif', fontWeight: 600 }}>
                        Sign Up
                    </div>
                    <form onSubmit={handleSubmit} style={{ margin: '50px 0 50px 0', fontSize: 16, display: 'flex', flexDirection: 'column' }}>
                        <InputLabelStyle>Nickname</InputLabelStyle>
                        <InputTextStyle type="text" placeholder="Nickname" value={nickname}
                                        onChange={(e) => setName(e.target.value)} />
                        <InputLabelStyle>Email</InputLabelStyle>
                        <InputTextStyle type="text" placeholder="Your email" value={email}
                                        onChange={(e) => setEmail(e.target.value)} />
                        <InputLabelStyle>Organization</InputLabelStyle>
                        <InputTextStyle type="text" placeholder="Organization" value={organization}
                                        onChange={(e) => setOrganization(e.target.value)} />
                        <InputLabelStyle>Password</InputLabelStyle>
                        <div style={{ position: 'relative' }}>
                            <InputTextStyle type={passwordVisible ? "text" : "password"} placeholder="Password" value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                            <div
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    right: '15px',
                                    top: '50%',
                                    transform: 'translateY(-60%)',
                                    cursor: 'pointer'
                                }}
                            >
                                {passwordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                            </div>
                        </div>
                        <InputLabelStyle>Confirm password</InputLabelStyle>
                        <div style={{ position: 'relative' }}>
                            <InputTextStyle type={confirmPasswordVisible ? "text" : "password"} placeholder="Confirm password" value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)} />
                            <div
                                onClick={toggleConfirmPasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    right: '15px',
                                    top: '50%',
                                    transform: 'translateY(-60%)',
                                    cursor: 'pointer'
                                }}
                            >
                                {confirmPasswordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                            </div>
                        </div>
                        {errorMessage && (
                            <div style={{ color: 'red' }}>
                                {errorMessage}
                            </div>
                        )}
                        <ButtonStyle>
                            Submit
                        </ButtonStyle>
                    </form>
                </SignupContainer>
            </div>
        </Container>
    );
}

export default SignupPage;
