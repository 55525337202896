import { useLocation } from 'react-router-dom';
import DisplayResult from '../components/DisplayResult';
import Menubar from "../components/Menubar";


function ResultPage() {
  const location = useLocation();
  const { vbmResult, s2tResult, fileName, lang } = location.state;

  return (
      <div>
          <Menubar/>
        {vbmResult ? (
                    <DisplayResult vbmResult={vbmResult} s2tResult={s2tResult} fileName={fileName} lang={lang}/>
        ) : (
            <p>No result data available.</p>
        )}
      </div>
  );
}

export default ResultPage;
