import styled, { keyframes } from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 100px 0 50px 0;
    background-color: #21005d;
    margin: 0;

    @media (max-width: 800px) {
        justify-content: flex-start;
        padding: 50px 0 20px 0;
        width: 800px;
    }
`;

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: DM Sans, sans-serif;
    border-radius: 15px; /* 모서리를 둥글게 설정 */
    padding: 50px; /* 내용에 여백 추가 */
    margin: 0 80px 50px 80px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 추가로 박스 스타일을 강조 */
    background-color: white;
    max-width: 1100px;
    width: 85%;
    justify-content: center;

    @media (max-width: 800px) {
        margin: 50px 50px 0 50px;
        padding: 20px;
    }
`;

const ResultContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    font-family: DM Sans, sans-serif;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
`;

const Container1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    font-family: DM Sans, sans-serif;
    width: 100%;
`;

const Container2 = styled(Container1)``;

const Container3 = styled(Container1)``;

const Title = styled.div`
    font-size: 26px;
    color: #6d6d6d;
    font-family: DM Sans, sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
`;

const Items = styled.div`
    font-size: 24px;
    color: #21005d;
    font-family: DM Sans, sans-serif;
    font-weight: 700;
    text-decoration: underline;
`;

const Result = styled.div`
    font-size: 22px;
    color: #21005d;
    font-family: DM Sans, sans-serif;
    font-weight: 700;
`;

const Risk = styled.div`
    font-size: 22px;
    color: #21005d;
    font-family: DM Sans, sans-serif;
    font-weight: 700;
`;

const ResultItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 100%;
    margin-left: 80px;
    margin-right: 50px;
`;

const MllmTextStyle = styled.div`
    font-size: 16px;
    font-family: DM Sans, sans-serif;
`;

// 텍스트 한 글자씩 나타나는 애니메이션 효과를 정의합니다.
const typingAnimation = keyframes`
    from { width: 0; }
    to { width: 25%; }
`;
// 텍스트 애니메이션 스타일을 정의합니다.
const TypingText = styled.div`
    overflow: hidden;
    white-space: nowrap;
    border-right: 0.1em solid orange; /* 커서 효과를 위한 선을 추가합니다. */
    animation: ${typingAnimation} 3s steps(40, end) infinite; /* 애니메이션을 적용합니다. */
`;

export {
    Container,
    ResultContainer,
    BoxContainer,
    Container1,
    Container2,
    Container3,
    Title,
    Items,
    Result,
    Risk,
    ResultItem,
    MllmTextStyle,
    TypingText,
};
