import styled from "styled-components";

const SignupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: DM Sans,sans-serif;
    Padding: 120px 0 100px 0;
`;

const InputLabelStyle = styled.label`
    font-size: 16px;
    font-weight: 700;
`;

const InputTextStyle = styled.input`
    width: 300px;
    height: 45px;
    background: white;
    border-radius: 8px;
    border: 1px #dee2e6 solid;
    padding: 0 10px;
    box-sizing: border-box;
    margin: 10px 0 20px 0;
    
    font-family: DM Sans, sans-serif;
    font-size: 14px;
`;

const ButtonStyle = styled.button`
    width: 300px;
    height: 50px;
    background: #21005D;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 15px;
    font-family: DM Sans, sans-serif
`;

export { SignupContainer, InputTextStyle, InputLabelStyle, ButtonStyle };
