import axios from 'axios';

const serviceType = process.env.REACT_APP_SERVICE_TYPE;

console.log('serviceType:', serviceType);

let api;

if (serviceType === 'prod') {
    console.log('prod mode');
    api = axios.create({
        baseURL: process.env.REACT_APP_API_URL || 'https://sommerz.mago52.com',
    });
} else {
    console.log('dev mode');
    api = axios.create({
        baseURL: process.env.REACT_APP_API_URL || 'http://147.47.41.49:5001',
    });
}

const useApi = () => {

    const dummyCall = async () => {
        try {
            await api.get('/');
            console.log('Dummy call succeeded.');
        } catch (error) {
            console.log('Dummy call failed:', error);
            // 에러를 무시하거나 처리할 수 있습니다.
        }
    };

    const createUser = async (user) => {
        try {
            const response = await api.post('/api/users', user);

            if (response.status === 400) {
                console.error('Failed to create user:', response.data.message);
                return response.data.message;
            }
            return response.data;
        } catch (error) {
            console.error('Failed to create user:', error.response.data.message);
            return error.response.data;
        }
    };

    const login = async (user) => {
        if (serviceType !== 'prod') await dummyCall();

        try {
            const response = await api.post('/api/users/login', user);
            if (response.status === 400) {
                console.error('Login failed:', response.data.message);
                return response.data.message;
            }
            const token = response.data;
            // 로그인 성공 시 토큰을 로컬 스토리지에 저장
            localStorage.setItem('token', token);

            console.log('Login success:', localStorage.getItem('token'));

            return response.data;
        } catch (error) {
            console.error('Login failed:', error.response.data.message);
            return error.response.data;
        }
    };

    // mllm api 호출
    const mllm = async (status) => {
        if (serviceType !== 'prod') await dummyCall();

        console.log('mllm:', status);
        try {
            const response = await api.post('/api/mllm', status, {
                headers: {
                    'authorization': localStorage.getItem('token')
                },
                withCredentials: true // CORS 요청 시 쿠키를 포함하도록 설정
            });

            if (response.message === ('Invalid token' || 'Token not provided') || response.status === (401 || 403)) {
                console.error('Failed to get mllm data:', response.message);
                localStorage.removeItem('token');
                return [];
            }

            return response.data;
        } catch (error) {
            console.error('Failed to get mllm data:', error.response.data.message);

            return {result: error.response.statusText + '. Please try again.'};
        }
    };

    // s2t api 호출
    const s2t = async (wavFile, lang) => {
        const formData = new FormData();
        formData.append('file', wavFile);
        formData.append('lang', lang);
        console.log('s2t:', wavFile);
        try{
            const response = await api.post('/api/s2t', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': localStorage.getItem('token')
                },
                withCredentials: true // CORS 요청 시 쿠키를 포함하도록 설정
            });

            return response.data;
        } catch (error){
            if (error.response.data.message === ('Invalid token' || 'Token not provided') || error.response.data.status === (401 || 403)) {
                localStorage.removeItem('token');
                return [];
            }
        }
    };

    // vbm api 호출
    const vbm = async (wavFile, language, gender, age) => {
        if (serviceType !== 'prod') await dummyCall();

        const formData = new FormData();
        formData.append('file', wavFile);
        formData.append('language', language);
        formData.append('gender', gender);
        formData.append('age', age);
        console.log('vbm:', wavFile);

        console.log('vbm:', language, age, gender);
        try{
            const response = await api.post('/api/vbm', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': localStorage.getItem('token')
            },
            withCredentials: true // CORS 요청 시 쿠키를 포함하도록 설정
        });
        return response.data;
    } catch (error) {
            if (error.response.data.message === ('Invalid token' || 'Token not provided') || error.response.data.status === (401 || 403)) {
                localStorage.removeItem('token');
                return [];
            }
        }
    };

    const dashboard = async () => {
        if (serviceType !== 'prod') await dummyCall();

        console.log('dashboard data');
        try{
            const response = await api.get('/api/dashboard', {
                headers: {
                    'authorization': localStorage.getItem('token')
                },
                withCredentials: true // CORS 요청 시 쿠키를 포함하도록 설정
            });
            return response.data;
        } catch (error){
            if (error.response.data.message === ('Invalid token' || 'Token not provided') || error.response.data.status === (401 || 403)) {
                localStorage.removeItem('token');
                return [];
            }
        }
    };

    const downloadAudio = async (fileId) => {
        const response = await api.get(`/api/download/${fileId}`, {
            headers: {
                'authorization': localStorage.getItem('token')
            },
            withCredentials: true, // CORS 요청 시 쿠키를 포함하도록 설정
            responseType: 'blob'
        });
        return response.data;
    };

    const deleteData = async (fileId) => {
        const response = await api.delete(`/api/delete/${fileId}`, {
            headers: {
                'authorization': localStorage.getItem('token')
            },
            withCredentials: true
        });
        return response.data;
    };

    return {createUser, login, mllm, s2t, vbm, dashboard, downloadAudio, deleteData};
};

export default useApi;
