import './App.css';
import {Route, Routes} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import ResultPage from "./pages/ResultPage";
import LoginPage from "./pages/LoginPage";
import React from "react";
import DashboardPage from "./pages/DashboardPage";
import SignupPage from "./pages/SignupPage";


function App() {
    return (
        <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/signup" element={<SignupPage/>}/>
            <Route path="/dashboard" element={<DashboardPage/>}/>
            <Route path="/result" element={<ResultPage/>}/>
        </Routes>
    );
}

export default App;
