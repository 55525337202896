import styled from "styled-components";

const EmotionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmotionTitle = styled.div`
  font-size: 26px;
  color: #6d6d6d;
  font-family: DM Sans, sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
`;

const EmotionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const EmotionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  font-family: DM Sans, sans-serif;
  border-radius: 15px; /* 모서리를 둥글게 설정 */
  padding: 15px; /* 내용에 여백 추가 */
  margin: 20px 20px 0 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 추가로 박스 스타일을 강조 */

  @media (max-width: 800px) {
    margin: 10px 0;
  }
`;

const EmotionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const EmotionLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #21005d;
  margin-bottom: 10px;
`;

const EmotionValue = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export { EmotionWrapper, EmotionTitle, EmotionRow, EmotionContainer, EmotionItem, EmotionLabel, EmotionValue };
