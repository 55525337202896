import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import mic from '../assets/mic.svg'; // 마이크 이미지 경로를 지정하세요
import { RecordContainer } from "../styles/RecordStyles";
import uuidv4 from "../utils/uuid";
// import { FFmpeg } from '@ffmpeg/ffmpeg';

const AudioRecorder = ({ onRecordingComplete }) => {
    const [onRec, setOnRec] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [fileName, setFileName] = useState('');
    // const [recordingTime, setRecordingTime] = useState(55);

    const canvasRef = useRef(null);
    const audioContext = useMemo(() => new (window.AudioContext || window.webkitAudioContext)(), []);
    const analyser = useRef(null);
    const dataArray = useRef(null);
    const animationId = useRef(null);
    // const timerId = useRef(null);

    // const ffmpeg = useMemo(() => new FFmpeg(), []);

    useEffect(() => {
        return () => {
            if (mediaRecorder && mediaRecorder.stream) {
                mediaRecorder.stream.getTracks().forEach(track => track.stop());
            }
        };
    }, [mediaRecorder, audioContext]);

    const drawWaveform = useCallback(() => {
        if (!analyser.current || !dataArray.current) return;
        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext('2d');
        const bufferLength = analyser.current.frequencyBinCount;

        const draw = () => {
            analyser.current.getByteTimeDomainData(dataArray.current);

            canvasCtx.fillStyle = '#FFF';
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = '#4F378B';

            canvasCtx.beginPath();
            const sliceWidth = canvas.width * 1.0 / bufferLength * 50;
            let x = 0;

            for (let i = 0; i < bufferLength; i += 50) {
                const v = dataArray.current[i] / 128.0;
                const y = v * canvas.height / 2;

                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();

            animationId.current = requestAnimationFrame(draw);
        };

        animationId.current = requestAnimationFrame(draw);
    }, []);

    const startRecording = useCallback(async () => {
        audioContext.resume();
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                setAudioChunks([]); // Reset audio chunks
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorder.start(800); // Recording interval in ms (0.8 second)
                setMediaRecorder(mediaRecorder);
                setOnRec(true);
                const source = audioContext.createMediaStreamSource(stream);
                analyser.current = audioContext.createAnalyser();
                analyser.current.fftSize = 2048;
                const bufferLength = analyser.current.frequencyBinCount;
                dataArray.current = new Uint8Array(bufferLength);
                source.connect(analyser.current);
                drawWaveform();

                mediaRecorder.ondataavailable = (e) => {
                    setAudioChunks((prev) => [...prev, e.data]);
                };

                console.log('음성 녹음이 시작되었습니다.');
                // startTimer(); // Start the timer

            })
            .catch((error) => {
                console.error('음성 녹음을 시작하는 중에 오류가 발생했습니다:', error);
            });
    }, [audioContext, drawWaveform]);


    const stopRecording = useCallback(() => {
        if (mediaRecorder && mediaRecorder.state === 'recording') {
            mediaRecorder.stop();
            setOnRec(false);
            // if (timerId.current) {
            //     clearInterval(timerId.current); // Clear the timer
            // }
            // setRecordingTime(0); // Reset recording time
            console.log('녹음이 중지되었습니다.');
            const fileName = uuidv4();
            setFileName(fileName);
            const data = { audioChunks, filename: fileName }; // 객체 생성
            onRecordingComplete && onRecordingComplete(data); // 녹음 완료 콜백 호출
            if (animationId.current) {
                cancelAnimationFrame(animationId.current);
            }
        } else {
            console.warn('녹음 중인 미디어 레코더가 없습니다.');
        }
    }, [mediaRecorder, audioChunks, onRecordingComplete]);

    const handleSaveAudio = useCallback(async () => {
        if (!audioChunks.length) {
            console.warn('저장할 오디오 데이터가 없습니다.');
            return;
        }

        const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
        const url = URL.createObjectURL(audioBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.webm`;
        a.click();
        URL.revokeObjectURL(url);
    }, [audioChunks, fileName]);

    // const startTimer = useCallback(() => {
    //     setRecordingTime(55); // Initialize recording time
    //     console.log('타이머가 시작되었습니다.');
    //     if (timerId.current) {
    //         console.warn('타이머가 이미 실행 중입니다.');
    //         clearInterval(timerId.current); // Clear existing timer if any
    //     }
    //     timerId.current = setInterval(() => {
    //         console.log('타이머 작동 중: ', recordingTime);
    //         setRecordingTime(prevTime => {
    //             if (prevTime >= 60) { // 1분 (60초) 타이머
    //                 console.log('녹음 시간이 초과되었습니다.: ', prevTime);
    //                 clearInterval(timerId.current);
    //                 stopRecording();
    //                 return 60; // 타이머가 정확히 1분이 되도록 설정
    //             }
    //             return prevTime + 1;
    //         });
    //     }, 1000);
    // }, [recordingTime, stopRecording]);






    return (
        <RecordContainer>
            <img src={mic} alt="mic" style={{ width: '35px', height: '35px', marginLeft: '10px' }} />
            <canvas style={{ marginRight: '10px', marginLeft: '10px', width: "50%" }} ref={canvasRef} width={200} height={50} />
            {/*<span style={{ marginLeft: '10px', marginRight: '10px', fontSize: '11px' }}>{`${recordingTime}s / 60s`}</span>*/}
            <button
                style={{
                    marginRight: '10px',
                    width: '55px',
                    height: '30px',
                    background: '#CCC2DC',
                    borderStyle: 'none',
                    borderRadius: '5px',
                    fontFamily: 'DM Sans,sans-serif',
                    fontSize: '13px',
                    fontWeight: 500,
                }}
                onClick={onRec ? stopRecording : startRecording}
            >
                {onRec ? 'Stop' : 'Start'}
            </button>
            <button
                style={{
                    width: '75px',
                    height: '30px',
                    background: '#CCC2DC',
                    borderStyle: 'none',
                    borderRadius: '5px',
                    fontFamily: 'DM Sans,sans-serif',
                    fontSize: '13px',
                    fontWeight: 500,
                }}
                onClick={handleSaveAudio}
            >
                Download
            </button>
        </RecordContainer>
    );
};

export default AudioRecorder;
