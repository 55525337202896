import styled from "styled-components";

const RecordContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: DM Sans,sans-serif;
    color: black;
    border: 1px solid #21005D;
    border-radius: 15px;
    padding: 5px 10px 5px 0;
    margin: 20px 0 0 0;
    width: 450px;
    height: 80px;
`;

export { RecordContainer };
