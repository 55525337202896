import React, { useEffect, useState } from "react";
import Menubar from "../components/Menubar";
import { Container } from "../styles/styles";
import { DashboardBox, DashhboardContainer, DashboardTable, ToggleButton } from "../styles/DashboardStyles";
import useApi from "../backend/users";
import { useNavigate } from "react-router-dom";
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { MdOutlineFileDownload } from 'react-icons/md';

const DashboardPage = () => {
    const { dashboard, downloadAudio, deleteData } = useApi();
    const [rowData, setRowData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = async () => {
        try {
            const data = await dashboard();
            if (localStorage.getItem('token') === null) {
                alert('Session expired. Please log in again.');
                navigate('/login');
            }
            setRowData(data);
            console.log('dashboard:', data);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleRowSelection = (index) => {
        if (selectedRows.includes(index)) {
            setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
        } else {
            setSelectedRows([...selectedRows, index]);
        }
    };

    const handleToggleMllm = (index) => {
        setExpandedRows(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const getShortMllm = (mllm) => {
        const maxLength = 100;
        if (mllm.length <= maxLength) {
            return mllm;
        }
        return mllm.substring(0, maxLength) + '...';
    };

    const handleDownload = (row) => {
        const dataStr = JSON.stringify(row.vbm, null, 2);
        const blob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${row.fileId}_vbm.json`;
        a.click();
        URL.revokeObjectURL(url);
    };

    const handleDownloadAudio = async (row) => {
        const audioData = await downloadAudio(row.fileId);
        const blob = new Blob([audioData], { type: 'audio/flac' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${row.fileId}`;
        a.click();
        URL.revokeObjectURL(url);
    };

    const handleDeleteSelected = async () => {
        try {
            const selectedIds = selectedRows.map(index => rowData[index].fileId);
            for (let id of selectedIds) {
                await deleteData(id);
            }
            // 데이터 삭제 후 선택 상태 초기화
            setSelectedRows([]);
            // 데이터 삭제 후 목록 다시 불러오기
            fetchData();
        } catch (error) {
            console.error('An error occurred while deleting:', error);
        }
    };

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = rowData.slice(indexOfFirstRow, indexOfLastRow);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(rowData.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
        // 페이지 변경 시 선택 상태 초기화
        setSelectedRows([]);
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => (prevPage < pageNumbers.length ? prevPage + 1 : prevPage));
        // 페이지 변경 시 선택 상태 초기화
        setSelectedRows([]);
    };

    return (
        <Container>
            <Menubar />
            <DashboardBox>
                <DashhboardContainer>
                    <div style={{color: '#21005D', fontSize: 30, fontFamily: 'DM Sans,sans-serif', fontWeight: 600,}}>
                        My Jobs
                    </div>
                    <DashboardTable className="table table-striped">
                        <thead>
                        <tr>
                            <th style={{textAlign: "center"}}>Select</th>
                            <th>Date</th>
                            <th>Email</th>
                            <th>File Name</th>
                            <th>Language</th>
                            <th>Age</th>
                            <th>Gender</th>
                            <th>MLLM</th>
                            <th>S2T</th>
                            <th style={{textAlign: "center"}}>VBM</th>
                            <th style={{textAlign: "center"}}>Audio File</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentRows.map((row, index) => (
                            <tr key={index}>
                                <td style={{width: "2%", textAlign: "center"}}>
                                    <input type="checkbox"
                                           onChange={() => handleRowSelection(index + indexOfFirstRow)}
                                           checked={selectedRows.includes(index + indexOfFirstRow)}/>
                                </td>
                                <td style={{
                                    width: "5%",
                                    fontSize: "13px"
                                }}>{new Date(row.date * 1000).toLocaleString()}</td>
                                <td style={{width: "5%"}}>{row.email}</td>
                                <td style={{width: "3%"}}>{row.fileId}</td>
                                <td style={{width: "5%", fontSize: "13px"}}>{row.language}</td>
                                <td style={{width: "5%", fontSize: "13px"}}>{row.age}</td>
                                <td style={{width: "5%", fontSize: "13px"}}>{row.gender}</td>
                                <td style={{width: '40%'}}>
                                    <ToggleButton onClick={() => handleToggleMllm(index + indexOfFirstRow)}>
                                        {expandedRows[index + indexOfFirstRow] ? <IoMdArrowDropup/> :
                                            <IoMdArrowDropdown/>}
                                    </ToggleButton>
                                    {expandedRows[index + indexOfFirstRow] ? row.mllm : getShortMllm(row.mllm)}
                                </td>
                                <td style={{width: '20%'}}>{row.s2t[0]?.content?.result?.text || ''}</td>
                                <td style={{textAlign: "center", fontSize: "20px", width: "5%"}}>
                                    <MdOutlineFileDownload onClick={() => handleDownload(row)}/>
                                </td>
                                <td style={{textAlign: "center", fontSize: "20px", width: "5%"}}>
                                    <MdOutlineFileDownload onClick={() => handleDownloadAudio(row)}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </DashboardTable>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative'
                    }}>
                        <div
                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0'}}>
                            <button onClick={handlePreviousPage} disabled={currentPage === 1}
                                    style={{margin: '0 10px', border: 'none', fontSize: '14px', padding: '5px 10px'}}>
                                Previous
                            </button>
                            {pageNumbers.map(number => (
                                <button
                                    key={number}
                                    onClick={() => setCurrentPage(number)}
                                    style={{
                                        margin: '0 5px',
                                        backgroundColor: currentPage === number ? '#007bff' : '#fff',
                                        color: currentPage === number ? '#fff' : '#000',
                                        border: '1px solid #ddd',
                                        padding: '5px 10px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {number}
                                </button>
                            ))}
                            <button onClick={handleNextPage} disabled={currentPage === pageNumbers.length}
                                    style={{margin: '0 10px', border: 'none', fontSize: '14px', padding: '5px 10px'}}>
                                Next
                            </button>
                        </div>
                        <div style={{position: 'absolute', right: 0, margin: '20px 0'}}>
                            <button onClick={handleDeleteSelected}
                                    style={{
                                        padding: '5px 10px',
                                        backgroundColor: '#B00020',
                                        color: 'white',
                                        fontSize: '14px',
                                        fontFamily: 'DM Sans',
                                        fontWeight: 500,
                                        textAlign: 'center',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </DashhboardContainer>
            </DashboardBox>
        </Container>
    );
};

export default DashboardPage;
