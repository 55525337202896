import styled from "styled-components";

const DashboardBox = styled.div`
    background-color: white;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 100px 0;

    @media (max-width: 1000px) {
        justify-content: flex-start;
    }
`;

const DashhboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1800px;
    width: 100%;
    margin: 0 50px;
`;

const DashboardTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th, td {
        padding: 8px;
        text-align: left;
    }

    th {
        background-color: #f2f2f2;
        font-size: 15px;
    }
    
    td {
        font-size: 12px;
    }

    tbody tr:nth-child(even) {
        background-color: #f2f2f2;
    }
`;

const ToggleButton = styled.button`
    color: #21005D;
    background: none;
    border: none;
    cursor: pointer;
`;

export { DashboardBox, DashhboardContainer, DashboardTable, ToggleButton };
