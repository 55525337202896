import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

const BOUNDARIES = {
    "Speech Speed": {'min': 0, 'max': 5},
    "RMS": {'min': 0, 'max': 0.5},
    "SNR": {'min': -20, 'max': 30},
    "Pitch": {'min': 40, 'max': 300},
    "Pitch Variation": {'min': 0, 'max': 50},
    "Spectral Centroid": {'min': 0.5, 'max': 2.0},
};

const createSpeedometer = (title, value, min, max, segmentColors) => {
    // 값이 min과 max를 넘지 않도록 제한
    const clampedValue = Math.max(min, Math.min(value, max));
    const formattedValue = parseFloat(clampedValue.toFixed(2));

    return (
        <div style={{ width: '200px', margin: "0 70px"}}>
            <h3 style={{ textAlign: 'center', color: '#21005D', fontFamily: 'DM Sans,sans-serif', fontSize: '16px' }}>{title}</h3>
            <ReactSpeedometer
                value={formattedValue}
                minValue={min}
                maxValue={max}
                segments={5}
                width={200}
                height={180}
                needleColor="#21005D"
                ringWidth={30}
                needleTransition="easeQuadIn"
                needleHeightRatio={0.6} // 바늘 길이 조정 (기본값 0.9, 0 ~ 1)
                segmentColors={segmentColors}
                valueTextFontSize="16"
                textColor="#21005D"
                currentValueText={`${formattedValue}`}
            />
        </div>
    );
};

const BiomarkerResults = ({ s2tResult, vbmResult }) => {
    const mentalResults = vbmResult.content?.result.biomarkers || {};
    const duration = mentalResults?.duration || 0;
    // const speechTimeline = mentalResults?.speech_timeline || [];
    const text = s2tResult?.content.result.text || '';
    const nEmjeols = text.replace(/\s+/g, '').length;
    const speechSpeed = parseFloat((nEmjeols / duration).toFixed(2));
    const rms = mentalResults?.energy?.rms || 0;
    const snr = mentalResults?.energy?.snr || 0;
    const pitch = mentalResults?.LibrosaPitch || {};
    const pitchMean = pitch?.f0_mean || 0;
    const pitchStdDev = pitch?.f0_std || 0;
    const spectralCentroid = mentalResults?.LibrosaSpectralCentroid || {};
    const spectralCentroidMean = spectralCentroid?.mean / 1000 || 0;

    return (
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                {createSpeedometer("Speech Speed", speechSpeed, BOUNDARIES["Speech Speed"].min, BOUNDARIES["Speech Speed"].max, ['#FFE7F5', '#FBABD5', '#F472B6', '#F53F8F', '#D30963'])}
                {createSpeedometer("RMS", rms, BOUNDARIES["RMS"].min, BOUNDARIES["RMS"].max, ['#FFE4C5', '#FFC18E', '#FB923C', '#F2702C', '#EA580C'])}
                {createSpeedometer("SNR", snr, BOUNDARIES["SNR"].min, BOUNDARIES["SNR"].max, ['#D3F0FF', '#A4CDFF', '#66AAFF', '#2E8CFF', '#265BF0'])}
                {createSpeedometer("Pitch", pitchMean, BOUNDARIES["Pitch"].min, BOUNDARIES["Pitch"].max, ['#E9E4FF', '#BEB0FF', '#AC90FD', '#9660F2', '#7C3AED'])}
                {createSpeedometer("Pitch Variation", pitchStdDev, BOUNDARIES["Pitch Variation"].min, BOUNDARIES["Pitch Variation"].max, ['#DEE5FF', '#BCC9FF', '#8F99FF', '#6C79FA', '#4F46E5'])}
                {createSpeedometer("Spectral Centroid", spectralCentroidMean, BOUNDARIES["Spectral Centroid"].min, BOUNDARIES["Spectral Centroid"].max, ['#C9F1DE', '#94F1C6', '#42DCA4', '#13CB88', '#059669'])}
            </div>
    );
};

export default BiomarkerResults;
