// src/components/CircularGauge.js
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {FaRegAngry, FaRegMeh, FaRegSadCry, FaRegSmile} from 'react-icons/fa';

const CircularGauge = ({ value, type }) => {

    const getIcon = (type) => {
        switch (type) {
            case 'happy':
                return <FaRegSmile size={25} color="#B69DF8" />;
            case 'angry':
                return <FaRegAngry size={25} color="#B0A7C0" />;
            // case 'surprise':
            //     return <FaRegSurprise size={25} color="#2196f3" />;
            case 'sad':
                return <FaRegSadCry size={25} color="#D29DAC" />;
            case 'neutral':
                return <FaRegMeh size={25} color="#E46962" />;
            default:
                return <FaRegMeh size={25} color="#9e9e9e" />;
        }
    }

    const pathColor = (type) => {
        switch (type) {
            case 'happy':
                return '#B69DF8';
            case 'angry':
                return '#B0A7C0';
            // case 'surprise':
            //     return '#2196f3';
            case 'sad':
                return '#D29DAC';
            case 'neutral':
                return '#E46962';
            default:
                return '#9e9e9e';
        }
    }

    return (
        <div style={{ position: 'relative', width: 70, height: 70 }}>
            <CircularProgressbar
                value={value}
                maxValue={100}
                styles={buildStyles({
                    pathColor: pathColor(type),
                    trailColor: '#d6d6d6',
                    textColor: '#f88',
                    textSize: '16px',
                })}
            />
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                {getIcon(type)}
            </div>
        </div>
    );
};

export default CircularGauge;
