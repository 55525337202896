import logo from "../assets/logo.svg";
import { Bar, Menu, MenuButton } from "../styles/MenubarStyles";
import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

const Menubar = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태를 관리하는 state

    useEffect(() => {
        // localStorage에 로그인 정보가 있는지 확인
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleNavigate = (path) => {
        if (path === '/dashboard' && !isLoggedIn) {
           alert('This page requires login.');
            return;
        }
        navigate(path);
    }

    const handleLogout = () => {
        // 로그아웃 시 localStorage에 저장된 token을 삭제
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        navigate('/');
        alert('You have been logged out.');
    }

    return (
        <Bar>
            <img src={logo} alt="logo" style={{ width: '100px', marginLeft: '50px' }} />
            <Menu>
                <MenuButton onClick={() => handleNavigate("/")}>Home</MenuButton>
                <MenuButton onClick={() => handleNavigate("/dashboard")}>Dashboard</MenuButton>
                {isLoggedIn ? (
                    <MenuButton onClick={ handleLogout}>Logout</MenuButton> // 로그인 상태일 때 Logout 버튼 표시
                ) : (
                    <MenuButton onClick={() => handleNavigate("/login")}>Login</MenuButton> // 로그아웃 상태일 때 Login 버튼 표시
                )}
                {/*<MenuButton onClick={() => handleNavigate("/signup")}>Sign Up</MenuButton>*/}
            </Menu>
        </Bar>
    )
}

export default Menubar;
