import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import AudioRecorder from '../components/AudioRecorder';
import brain from '../assets/brain.svg';
import wave1 from '../assets/wave1.svg';
import wave2 from '../assets/wave2.svg';
import files from '../assets/file.svg';
import {
    MainContainer,
    LeftPanel,
    RightPanel,
    Title,
    SvgBackground,
    ContentContainer,
    Footer,
    StyledLink,
    boxStyles,
    SelectContainer,
    SelectLabel,
    Select,
    Input,
    Dropzone,
    DropzoneMessage
} from '../styles/styles';
import Menubar from "../components/Menubar";
import uuidv4 from "../utils/uuid";
import useApi from "../backend/users";
import {languages} from "p5/translations";

function HomePage() {
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('Male');
    const [file, setFile] = useState(null);
    const [audioData, setAudioData] = useState(null);
    const navigate = useNavigate();
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const [fileName, setFileName] = useState('');
    const [loading, setLoading] = useState(false); // 결과 분석 중 여부를 나타내는 상태 변수

    const { vbm, s2t } = useApi();

    const handleLanguageSelect = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const handleAgeInput = (event) => {
        const value = event.target.value;

        // 숫자만 허용하는 정규 표현식
        const numberRegex = /^[0-9]*$/;

        // 입력 값이 숫자만 포함하거나 빈 문자열인지 확인
        if (numberRegex.test(value)) {
            const numberValue = Number(value);

            // 값이 범위 내에 있는지 확인
            if (numberValue >= 0 && numberValue <= 100) {
                setAge(value);
            }
        }
    };

    const handleGenderSelect = (event) => {
        setGender(event.target.value);
    };

    const onDrop = useCallback((acceptedFiles) => {
        const allowedExtensions = ['.wav', '.mp3', '.flac', '.aac'];
        const acceptedFile = acceptedFiles[0];
        const fileExtension = acceptedFile.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(`.${fileExtension}`)) {
            alert('Only audio file formats are allowed.');
            return;
        }

        setFile(acceptedFile);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'audio/*',
    });

    const handleRecordingComplete = ({ audioChunks, filename }) => {
        setAudioData(audioChunks);
        setFileName(filename);
    };

    const handleResult = async () => {
        if (age === '') {
            alert('Please enter your age.');
            return;
        }

        try {
            if (localStorage.getItem('token') !== null) {
                setLoading(true); // 분석이 시작됨을 나타내는 상태 변경
                if (audioData) {
                    const combinedBlob = new Blob(audioData, { type: 'audio/webm' });

                    // Create File object with AAC Blob
                    const aacFile = new File([combinedBlob], fileName + '.webm', { type: 'audio/webm' });

                    // Call API function with AAC file
                    await handleApiResult(aacFile);
                } else if (file) {
                    const arrayBuffer = await file.arrayBuffer();
                    const audioBlob = new Blob([arrayBuffer], { type: 'audio/aac' });

                    const aacFile = new File([audioBlob], uuidv4() + '_' + file.name.replace(/\.[^/.]+$/, "") + '.aac', { type: 'audio/aac' });

                    await handleApiResult(aacFile);
                } else {
                    alert('Please provide an audio file or record audio.');
                }
            } else {
                alert('Please log in to use this service.');
                navigate('/login');
            }
        } catch (error) {
            console.error('업로드 중 오류가 발생했습니다:', error);
        } finally {
            setLoading(false); // 결과 분석이 완료됨을 나타내는 상태 변경
        }
    };

    const handleApiResult = async (wavFile) => {
        try {
            const vbmResult = await vbm(wavFile, selectedLanguage, gender, age);
            // Speech-to-text API call
            const s2tResult = await s2t(wavFile, selectedLanguage);

            // else
            if (localStorage.getItem('token') === null) {
                alert('Session expired. Please log in again.');
                navigate('/login');
                return;
            }

            if (vbmResult && s2tResult) {
                navigate('/result', { state: { vbmResult: vbmResult, s2tResult: s2tResult, fileName: wavFile.name, lang: selectedLanguage } });
                setFileName('');
            } else {
                alert('An error occurred while processing the audio file. Please try again.');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    return (
        <MainContainer>
            <Menubar />
            <LeftPanel>
                <SvgBackground src={brain} alt="background" />
                <Title>Dementia-Depression<br />Vocal Detector</Title>
                <img src={wave1} alt="wave1"
                     style={{ position: 'absolute', top: '30%', left: '-1%', width: '107%' }} />
                <img src={wave2} alt="wave2"
                     style={{ position: 'absolute', top: '35%', left: '-3%', width: '104%' }} />
                <ContentContainer>
                    Distinguishing dementia and depression, two prevalent age-related disorders, remains challenging
                    due to shared symptoms. We pioneer a voice-based approach, using machine learning and deep
                    learning on short recordings, to achieve rapid and accurate diagnosis. This pilot study
                    highlights the potential of vocal biomarkers for early detection and improved management of
                    these debilitating conditions. Our future aim is to expand this framework, predicting and
                    monitoring a wider range of age-related diseases for personalized healthcare and a healthier
                    aging population.
                </ContentContainer>
                <Footer>
                    By using this service, you agree to the terms of service and privacy policy. Research partners
                    can use the service in bulk and free without time constraints.
                    <br /><br />
                    Contact: <StyledLink href="mailto:powerzines@snu.ac.kr">powerzines@snu.ac.kr</StyledLink>
                </Footer>
            </LeftPanel>
            <RightPanel>
                <div style={boxStyles}>
                    Prediction of <br/>Dementia/Depression
                </div>
                <SelectContainer>
                    <SelectLabel>Language</SelectLabel>
                    <Select onChange={handleLanguageSelect} value={selectedLanguage}>
                        <option value="Korean">Korean</option>
                        <option value="English">English</option>
                    </Select>
                </SelectContainer>
                <div style={{
                    color: '#1D192B',
                    fontSize: 12,
                    fontFamily: 'DM Sans,sans-serif',
                    fontWeight: 400,
                    wordWrap: 'break-word',
                    marginBottom: '30px',
                }}>Please select the language of the audio to be analyzed.
                </div>
                <div style={{
                    color: '#1D192B',
                    fontSize: 22,
                    fontFamily: 'DM Sans,sans-serif',
                    fontWeight: 700,
                    wordWrap: 'break-word',
                    marginBottom: '20px',
                }}>
                    Demographics
                </div>
                <SelectContainer>
                    <SelectLabel>Age</SelectLabel>
                    <Input type="text" onChange={handleAgeInput} value={age} min={0} max={100}/>
                    <SelectLabel>Gender</SelectLabel>
                    <Select onChange={handleGenderSelect} value={gender}>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </Select>
                </SelectContainer>
                <SelectLabel>Upload Audio File</SelectLabel>
                <div style={{display: 'flex', flexDirection: 'column', margin: '0px 15px 20px 15px'}}>
                    <Dropzone {...getRootProps()}>
                        <img src={files} alt="file"
                             style={{width: '100%', height: '40px', marginTop: '10px', marginBottom: '15px'}}/>
                        <input {...getInputProps()} />
                        {file ? (
                            <DropzoneMessage>{file.name}</DropzoneMessage>
                        ) : (
                            <DropzoneMessage>Drop your file here or record audio below.</DropzoneMessage>
                        )}
                    </Dropzone>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: "90%",
                    }}>
                        <div style={{
                            color: '#1D192B',
                            fontSize: 12,
                            fontFamily: 'DM Sans,sans-serif',
                            fontWeight: 400,
                            wordWrap: 'break-word',
                        }}>Supported file formats: .mp3, .wav, .flac, .aac
                        </div>
                    </div>
                </div>

                <AudioRecorder onRecordingComplete={handleRecordingComplete}/>
                <button onClick={handleResult}
                        style={{
                            width: '30%',
                            height: '50px',
                            backgroundColor: '#21005D',
                            color: 'white',
                            fontSize: '18px',
                            fontFamily: 'DM Sans,sans-serif',
                            fontWeight: 500,
                            borderRadius: '10px',
                            border: 'none',
                            cursor: 'pointer', // age가 비어 있으면 커서 비활성화
                            margin: '50px 0 0 0',
                        }}
                >
                    {loading ? "Analyzing..." : "Result"}
                </button>
            </RightPanel>
        </MainContainer>
    );
}

export default HomePage;
