import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    font-family: 'DM Sans',sans-serif;
    height: 100%;
    min-height: 100vh;
`;

const MainContainer = styled.div`
    background-color: #21005D;
    display: flex;
    flex-direction: row;
    align-items: start; /* 자식 요소들을 위쪽에서부터 정렬 */
    justify-content: flex-start; /* 자식 요소들을 좌우로 정렬 */
    color: white;
    min-width: 1300px;
    height: 100%;
    position: relative; /* 추가 */


    @media screen and (min-width: 1500px) {
        justify-content: center;
        height: 100%;
        width: 100%;
        //width: 100vw;
    }
    
    @media screen and (min-height: 1300px) {
        height: 100vh;
    }
`;

const Panel = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
`;

const LeftPanel = styled(Panel)`
    flex: 1;
    min-width: 500px;
    max-width: 750px;
    padding: 50px 100px 0 50px;
    position: relative;
`;

const RightPanel = styled(Panel)`
    flex: 1;
    min-width: 600px;
    max-width: 800px;
    height: 800px;
    padding: 50px 0 50px 0;
    background-color: white;
    color: #21005D;
    border-radius: 10px;
    margin-top: 150px;
    margin-bottom: 100px;
    margin-right: 50px;
    align-items: center;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 1500px) {
        padding: 80px 0 50px 0;
        margin-top: 180px;
    }
`;

const Title = styled.h1`
    color: white;
    font-size: 37px;
    font-family: 'DM Sans',sans-serif;
    font-weight: 600;
    word-wrap: break-word;
    text-align: left;
    margin: 100px 0 0 0;
    position: relative;
    z-index: 1; /* Ensure the text is on top */

    @media screen and (min-width: 1500px) {
        margin: 110px 0 0 0;
    }
`;

const SvgBackground = styled.img`
    position: absolute;
    top: 70px;
    left: 335px;
    width: 150px;
    height: 230px;
    z-index: 0; /* Ensure the SVG is behind the text */
    pointer-events: none; /* Ensure the SVG doesn't block interactions with the text */
    //opacity: 0.5;  /* Optional: Make the SVG semi-transparent */
`;

const ContentContainer = styled.p`
    width: 450px;
    color: white;
    font-size: 16px;
    font-family: 'DM Sans',sans-serif;
    font-weight: 400;
    line-height: 1.2;
    word-wrap: break-word;
    text-align: left;
    margin: 300px 0 0 0;
    
    @media screen and (min-width: 1500px) {
        margin: 300px 0 0 0;
    }
`;

const Footer = styled.div`
    font-size: 14px;
    font-family: 'DM Sans',sans-serif;
    max-width: 450px;
    margin-top: 150px;

    @media screen and (min-width: 1500px) {
        margin: 200px 0 0 0;
    }
`;

const StyledLink = styled.a`
    color: white;
    text-decoration: underline;
`;

const boxStyles = {
    textAlign: 'center',
    color: '#21005D',
    fontSize: '25px',
    fontFamily: 'DM Sans, sans-serif',
    fontWeight: '700',
    wordWrap: 'break-word',
    marginBottom: '40px',
};

const SelectContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
`;

const SelectLabel = styled.p`
    color: #21005D;
    font-size: 16px;
    font-family: 'DM Sans',sans-serif;
    font-weight: 600;
    word-wrap: break-word;
    margin-right: 10px;
`;

const Select = styled.select`
    width: 103px;
    height: 34px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px #dee2e6 solid;
    display: flex;
    align-items: center;
    text-align: center;
    color: #21005D;
    font-size: 14px;
    font-family: 'DM Sans',sans-serif;
    font-weight: 500;
    line-height: 20px;
    word-wrap: break-word;
    margin-right: 30px;
`;

const Input = styled.input`
    width: 92px;
    height: 34px;
    background: white;
    border-radius: 8px;
    border: 1px #dee2e6 solid;
    padding: 0 10px;
    box-sizing: border-box;
    margin-right: 30px;
`;

const Dropzone = styled.div`
    width: 500px;
    height: 100px;
    color: #21005D;
    font-size: 18px;
    font-family: 'DM Sans',sans-serif;
    font-weight: 500;
    word-wrap: break-word;
    margin-bottom: 10px;
    border-radius: 15px; /* 모서리를 둥글게 설정 */
    padding: 15px; /* 내용에 여백 추가 */
    background-color: #fff; /* 배경 색상 설정 */
    border: 2px dashed #21005D;
`;

const DropzoneMessage = styled.p`
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
`;


export {
    Container,
    MainContainer,
    LeftPanel,
    RightPanel,
    Title,
    SvgBackground,
    ContentContainer,
    Footer,
    StyledLink,
    boxStyles,
    SelectContainer,
    SelectLabel,
    Select,
    Input,
    Dropzone,
    DropzoneMessage,
};
