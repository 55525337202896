import {ButtonStyle, InputLabelStyle, InputTextStyle, SignupContainer} from "../styles/SignupStyles";
import React, {useState} from "react";
import Menubar from "../components/Menubar";
import {Container} from "../styles/styles";
import useApi from "../backend/users";
import {useNavigate} from "react-router-dom";
import {FaRegEye, FaRegEyeSlash} from 'react-icons/fa';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [visible, setVisible] = useState(false); // visible 상태를 추가합니다.
    const navigate = useNavigate();

    const {login} = useApi();

    const toggleVisibility = () => {
        setVisible(!visible); // visible 상태를 토글합니다.
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email === '' || password === '') {
            alert('Please enter your email and password.');
            return;
        }

        const user = {email, password};
        const data = await login(user);
        console.log('data', data);
        if (data.message === "User not found") {
            alert('User does not exist.');
        } else if (data.message === "Password incorrect") {
            alert('Incorrect password.');
        } else {
            alert('Login successful');
            navigate('/');
        }

        setEmail('');
        setPassword('');
    }

    return (
        <Container>
            <Menubar/>
            <div style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                width: "100%",
                height: "100%"
            }}>
                <SignupContainer>
                    <div style={{color: '#21005D', fontSize: 40, fontFamily: 'DM Sans,sans-serif', fontWeight: 600}}>
                        Login
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div style={{margin: '50px 0 50px 0', fontSize: 16, display: 'flex', flexDirection: 'column'}}>
                            <InputLabelStyle>Email</InputLabelStyle>
                            <InputTextStyle type="text" placeholder="Your email" value={email}
                                            onChange={(e) => setEmail(e.target.value)}/>
                            <InputLabelStyle>Password</InputLabelStyle>
                            <div style={{position: 'relative'}}>
                                <InputTextStyle type={visible ? "text" : "password"} placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}/>
                                <div style={{
                                    position: 'absolute',
                                    right: '15px',
                                    top: '50%',
                                    transform: 'translateY(-60%)',
                                    cursor: 'pointer'
                                }}
                                     onClick={toggleVisibility}>
                                    {visible ? <FaRegEye/> : <FaRegEyeSlash/>}
                                </div>
                            </div>
                            <ButtonStyle>
                                Submit
                            </ButtonStyle>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'end',
                                justifyContent: 'center'
                            }}>
                                <div style={{color: '#605D64', fontSize: 14, fontWeight: 700, margin: '20px 0 20px 0'}}>
                                    If you don't have an account,
                                </div>
                                <button onClick={() => navigate('/signup')} style={{
                                    color: '#21005D',
                                    fontSize: 16,
                                    fontFamily: 'DM Sans,sans-serif',
                                    fontWeight: 700,
                                    margin: '20px 0 20px 0',
                                    border: 'none',
                                    background: 'none',
                                    cursor: 'pointer'
                                }}>Sign Up
                                </button>
                            </div>
                        </div>
                    </form>
                </SignupContainer>
            </div>
        </Container>
    );
}

export default LoginPage;
