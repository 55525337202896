import styled from "styled-components";

const Bar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    z-index: 2;
    background-color: #21005D;
    padding: 22px 0;
`;

const Menu = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
`;

const MenuButton = styled.button`
    background-color: transparent;
    color: white;
    border: none;
    font-size: 18px;
    font-family: DM Sans,sans-serif;
    font-weight: 700;
    margin: 0 20px 0 20px;
    cursor: pointer;
`;

export { Bar, Menu, MenuButton };
